/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-11 13:51:27
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-24 13:05:59
 */
export enum OrderTrackingOrderProgressionKey {
  OrderSubmit = "Submit",
  OrderVerified = "Verified",
  OrderAssign = "Order Placed",
  OrderApproved = "Inventory Confirmation",
  OrderShipped = "EU Shipment",
  ArticleReview = "Transit & QC Warehouse",
  ArticleShipped = "Shipped",
  ArticleDelivered = "Delivered",
}

export enum OrderTrackingShipmentTrackingKey {
  Shipped = "Shipped",
  Virtual = "Virtual",
  Delivery = "Delivery",
  ExportCustomDeclaration = "Export Customs Declaration",
  OverseasShipment = "Overseas Shipment",
  Delivered = "Delivered",
}
