import { CommonLanguageType } from "../type/common";
import {
  OrderTrackingOrderProgressionKey,
  OrderTrackingShipmentTrackingKey,
} from "../type/orderTracking";

/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-12 17:26:54
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-07 16:55:07
 */

/**
 * @description: 获取订单进程图标
 * @return {*}
 */
export const getOrderTrackingOrderProgressionMap = (key): string => {
  const result = new Map([
    [
      OrderTrackingOrderProgressionKey.OrderSubmit,
      "media/icons/duotune/general/gen005.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.OrderVerified,
      "media/icons/duotune/files/fil025.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.OrderAssign,
      "media/svg/custom/assign.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.OrderApproved,
      "media/svg/custom/confirm.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.OrderShipped,
      "media/icons/duotune/ecommerce/ecm006.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.ArticleReview,
      "media/svg/custom/inspection.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.ArticleShipped,
      "media/svg/custom/plane.svg",
    ],
    [
      OrderTrackingOrderProgressionKey.ArticleDelivered,
      "media/svg/custom/sign-for.svg",
    ],
  ]);
  return result.get(key) || "";
};

/**
 * @description: 获取订单进程文本
 * @return {*}
 */
export const getOrderTrackingOrderProgressionKeyMap = (
  status: OrderTrackingOrderProgressionKey,
  language: CommonLanguageType
): string => {
  const result = new Map([
    [
      OrderTrackingOrderProgressionKey.OrderSubmit,
      language("salesOrderOverview.orderProgressionOrderSubmitted"),
    ],
    [
      OrderTrackingOrderProgressionKey.OrderVerified,
      language("salesOrderOverview.orderProgressionOrderVerified"),
    ],
    [
      OrderTrackingOrderProgressionKey.OrderAssign,
      language("salesOrderOverview.orderProgressionOrderAssigned"),
    ],
    [
      OrderTrackingOrderProgressionKey.OrderApproved,
      language("salesOrderOverview.orderProgressionOrderApproved"),
    ],
    [
      OrderTrackingOrderProgressionKey.OrderShipped,
      language("salesOrderOverview.orderProgressionOrderShipped"),
    ],
    [
      OrderTrackingOrderProgressionKey.ArticleReview,
      language("salesOrderOverview.orderProgressionArticleReviewed"),
    ],
    [
      OrderTrackingOrderProgressionKey.ArticleShipped,
      language("salesOrderOverview.orderProgressionArticleShipped"),
    ],
    [
      OrderTrackingOrderProgressionKey.ArticleDelivered,
      language("salesOrderOverview.orderProgressionArticleDelivered"),
    ],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取订单跟踪文本
 * @return {*}
 */
export const getOrderTrackingShipmentTrackingKeyMap = (
  status: OrderTrackingShipmentTrackingKey,
  language: CommonLanguageType
): string => {
  const result = new Map([
    [
      OrderTrackingShipmentTrackingKey.Shipped,
      language("salesOrderOverview.orderProgressionArticleShipped"),
    ],
    [
      OrderTrackingShipmentTrackingKey.Delivery,
      language("salesOrderOverview.orderShipmentTrackingAllArticlesPrepared"),
    ],
    [
      OrderTrackingShipmentTrackingKey.ExportCustomDeclaration,
      language(
        "salesOrderOverview.orderShipmentTrackingCustomClearanceAccomplished"
      ),
    ],
    [
      OrderTrackingShipmentTrackingKey.OverseasShipment,
      language("salesOrderOverview.orderShipmentTrackingStartOverseasShipment"),
    ],
    [
      OrderTrackingShipmentTrackingKey.Delivered,
      language("salesOrderOverview.orderShipmentTrackingDeliverToCustomer"),
    ],
  ]);
  return result.get(status) || "";
};
