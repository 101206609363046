
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  useAttrs,
  computed,
  onUpdated,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import FsLightbox from "fslightbox-vue/v3";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  SalesOrderOverview,
  salesOrderOverviewFormData,
} from "@/core/directive/interface/salesOrder";
import JwtService from "@/core/services/JwtService";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { ApiOrderTracking } from "@/core/api";
import {
  OrderTrackingOrderProgression,
  OrderTrackingOrderProgressionItem,
  OrderTrackingShipmentTracking,
} from "@/core/directive/interface/orderTracking";
import { MenuComponent } from "@/assets/ts/components";
import {
  OrderTrackingOrderProgressionKey,
  OrderTrackingShipmentTrackingKey,
} from "@/core/directive/type/orderTracking";
import {
  getOrderTrackingOrderProgressionKeyMap,
  getOrderTrackingOrderProgressionMap,
  getOrderTrackingShipmentTrackingKeyMap,
} from "@/core/directive/function/orderTracking";
import {
  getServiceProviderMap,
  getShipmentStatusMap,
} from "@/core/directive/function/shipment";
import { isAuditView } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import { previewOrderImages } from "@/core/directive/function/order";
import { CommonDateType } from "@/core/directive/type/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";

export default defineComponent({
  name: "order-tracking",
  components: { FsLightbox, ProductInfo, SkuInfo },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs(); // 接收父路由参数

    const loading = ref(true);
    const salesOrderOverviewData = ref(
      Object.assign({}, salesOrderOverviewFormData)
    );
    const orderAssginOrderProgressionData = ref<
      Array<OrderTrackingOrderProgression>
    >([]);
    const orderTrackingShipmentTracking = ref<
      Array<OrderTrackingShipmentTracking>
    >([]);

    const options = ref({
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      scheduleLoading: true,
      shipmentLoading: true,
      shipmentScheduleIsVirtual: true,
    });

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        const data = attrs.detailData as SalesOrderOverview;
        salesOrderOverviewData.value = data;
      }
    );

    const getOrderBatchTracking = async () => {
      options.value.shipmentLoading = true;
      const { data } = await ApiOrderTracking.getOrderBatchTracking({
        order_id: route.params.id,
      });
      options.value.shipmentLoading = false;
      if (data.code == 0) {
        let items: OrderTrackingShipmentTracking[] = Object.values(data.data);
        items.forEach((item) => {
          item.sku_list = Object.values(item.sku_list);
          item.isShow = true;
          options.value.shipmentScheduleIsVirtual = item.tracking.find(
            (item) => item.key !== OrderTrackingShipmentTrackingKey.Virtual
          )
            ? false
            : true;
        });
        options.value.shipmentScheduleIsVirtual = Object.keys(data.data).find(
          (item) => item !== OrderTrackingShipmentTrackingKey.Virtual
        )
          ? false
          : true;
        orderTrackingShipmentTracking.value = items;
      }
    };

    const getOrderProgression = async () => {
      options.value.scheduleLoading = true;
      const { data } = await ApiOrderTracking.getOrderProgression({
        order_id: route.params.id,
      });
      options.value.scheduleLoading = false;
      if (data.code === 0) {
        let items: OrderTrackingOrderProgression[] = data.data;
        items.forEach((item) => {
          item.sku_list = Object.values(item.sku_list);
        });
        orderAssginOrderProgressionData.value = items;
      }
    };

    const getFormInfo = () => {
      // loading.value = true;
      Promise.all([getOrderProgression(), getOrderBatchTracking()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getOrderProgressionTarget = computed(() => {
      return (key: string, index: number) => {
        let str = "kt_order_progression_" + index,
          arr = key.replace("&", "").split(" ");
        arr.map((item) => {
          str += "_" + item.toLocaleLowerCase();
        });
        return str;
      };
    });

    const getOrderProgressionTracking = computed(() => {
      return (item: OrderTrackingOrderProgressionItem[]) => {
        let arr: string[] = [],
          items: OrderTrackingOrderProgressionItem[] = [];
        item.map((list) => {
          if (list.completion.length === 5) {
            let tracking_no: string = list.completion[3] as string;
            let service_provider: string = list.completion[4] as string;
            let str = tracking_no + service_provider;
            if (arr.indexOf(str) === -1) {
              arr.push(str);
              items.push(list);
            }
          }
        });
        return items;
      };
    });

    const getName = computed(() => {
      return (row) => {
        return JwtService.getLang() === "zh_CN"
          ? row.sku_name_cn
          : row.sku_name_en;
      };
    });

    const changeShipmentShow = (item) => {
      item.isShow = !item.isShow;
    };

    const getOrderProgressionCurrentStatus = computed(() => {
      return (index: number) => {
        let flag = false;
        const lastDoneIndex = _.findLastIndex(
          orderAssginOrderProgressionData.value,
          (item) => {
            return item.crossed === 1;
          }
        );
        if (lastDoneIndex + 1 === index) {
          flag = true;
        }
        return flag;
      };
    });

    const getVatArr = computed(() => {
      let vatArr: any[] = [],
        vatPercent: string[] = [];
      salesOrderOverviewData.value.ordered_items.map((item) => {
        if (vatPercent.indexOf(item.tax_percent) > -1) {
          vatArr[vatPercent.indexOf(item.tax_percent)].base_tax_amount +=
            Number(item.base_tax_amount);
        } else {
          vatPercent.push(item.tax_percent);
          vatArr.push({
            tax_percent: item.tax_percent,
            base_tax_amount: Number(item.base_tax_amount),
          });
        }
      });
      return vatArr;
    });

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const customFormatDate = (date: CommonDateType, format = "YYYY.MM.DD") => {
      return formatDate(date, format);
    };

    const customFormatDateTime = (
      date: CommonDateType,
      format = "YYYY.MM.DD, HH:mm"
    ) => {
      return formatDateTime(date, format);
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        loading.value = false;
        const data = attrs.detailData as SalesOrderOverview;
        salesOrderOverviewData.value = data;
      }
      getFormInfo();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      OrderTrackingOrderProgressionKey,
      getOrderTrackingOrderProgressionMap,
      getOrderTrackingOrderProgressionKeyMap,
      OrderTrackingShipmentTrackingKey,
      getOrderTrackingShipmentTrackingKeyMap,
      getServiceProviderMap,
      getShipmentStatusMap,
      route,
      loading,
      salesOrderOverviewData,
      orderAssginOrderProgressionData,
      orderTrackingShipmentTracking,
      options,
      getOrderProgressionTarget,
      getOrderProgressionTracking,
      getOrderProgressionCurrentStatus,
      getVatArr,
      getName,
      changeShipmentShow,
      previewImages,
      customFormatDate,
      customFormatDateTime,
      isAuditView,
    };
  },
});
